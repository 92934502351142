<template>
  <li class="community-postlist-item" style="list-style: none">
    <div class="community-postlist-item-header">
      <div class="community-postlist-item-header-avatar" @click="jumppersonal(item)">
        <van-image
            round
            cover
            fit="cover"
            :src="item.headImg ? item.headImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
          />
      </div>
      <div class="community-postlist-item-header-info">
        <p class="community-postlist-item-header-info-nickname">
          {{ item.nickName ? item.nickName : item.mobile  }}
        </p>
        <p class="community-postlist-item-header-info-date">
          {{ item.senddate }}
        </p>
      </div>

      <div class="community-postlist-item-header-more"  @click="openPopup" v-if="postType != 1">
        <img  src="../../assets/image/post-more.png" alt=""/>
      </div>

    </div>
    <div class="community-postlist-item-content">
      <!--          {{ item.content }}-->
<!--      {{item.postType}}-->
      <div class="box">
        <span class="community-postlist-item-content-label"
          v-bind:class="{isPost:isPost,isBug:isBug,isSuggest:isSuggest,isActivity:isActivity}">{{label}}</span>
          {{item.title?item.title:''}}
      </div>
      <div v-html="item.content" class="postContent"></div>
    </div>
    <ul class="community-postlist-item-imgwrap" v-if="item.pics">
      <li
        class="community-postlist-item-imgwrap-item"
        v-for="(img, index) in item.pics"
        :key="img.id"
        @click="getImg(index)"
      >
        <img :src="img.picPath" alt="" />
      </li>
      <!-- <li class="community-postlist-item-imgwrap-item">
        <img src="https://picsum.photos/seed/picsum/200/300" alt="" />
      </li>
      <li class="community-postlist-item-imgwrap-item"> 
        <img src="https://picsum.photos/200/300?grayscale" alt="" />
      </li> -->
    </ul>
    <ul class="community-postlist-item-actionbar" v-if="isActivity || isPost">
      <li
        class="community-postlist-item-actionbar-item"
        @click="doEndorse()"
      >
        <img src="~@image/zan-red.png" alt="" v-if="item.endorse" />
        <img src="~@image/zan-hui.png" alt="" v-else />
        {{ item.endorseCount }}
      </li>
      <li class="community-postlist-item-actionbar-item" @click="jumpdetails(item)">
        <img src="~@image/pinglun.png" alt="" />{{ item.commentCount ==null ? 0: item.commentCount}}
      </li>
      <li
        class="community-postlist-item-actionbar-item" @click="handleshare()"
      >
       <img src="~@image/forward.png" alt="" /> 
      </li>
    </ul>
     
    
  </li>
</template>

<script>
import { doEndorse,Collection } from "@api/community";
import { ImagePreview } from "vant";
export default {
  name: "PostList",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data(){
    return{
      label: '',
      // exchange: true
      isBug: false,
      isSuggest: false,
      isActivity: false,
      isPost: false,
      operation:false,
      instance_before:'',
      showShare: false,
      postType:""
    }
  },
  computed: {
    imgList() {
      return this.item.pics.map((item) => {
        return item.picPath;
      });
    },
  },
  methods: {
     jumppersonal(item){
      this.$router.push({
        name:"Userpersonal",
        query:{
          id:item.userid
        }
      })
    },
    
    openPopup(){
      this.$emit("showOperation")
    },
    
    handleshare(){
      this.$emit("handleshare",this.showShare)
    },

    async doEndorse() {
      let id = this.$route.query.id
      const params = {
        targetId: id,
        praiseType: 0
      };
      const result = await doEndorse(params);
      if (result.code === 0) {
        // this.$emit("updateArticleData");
        // this.getArticleData();
        this.item.endorse = !this.item.endorse
        this.item.endorse ? this.item.endorseCount ++ : this.item.endorseCount --
      }
    },
  

    jumpdetails(item){
      this.$emit("jumpdetails",item)
    },  

    getImg(index) {
        const instance_before = ImagePreview({
            images: this.imgList,
            showIndex: true,
            startPosition: index,
            asyncClose: true,
            closeable: true,
            onClose(){
              if(window.android){
                    let obj = {
                    action:'imagePreview',
                    data:{
                      type:0, 
                    }
                  }
                 window.android.postMessage(JSON.stringify(obj))
              }else if(window.webkit){
                    let obj = {
                    action:'imagePreview',
                    data:{
                      type:0, 
                    }
                  }
                 window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj))
              }else{
                return
              }
              
            }
          });
      
       this.$nextTick(()=>{
          let close = document.getElementsByClassName("van-image-preview__close-icon")[0]
          close.addEventListener("click",function(){
              instance_before.close()
          })
       })
      if(window.android){
        let obj = {
          action:'imagePreview',
          data:{
            type:1, 
          }
        }
        window.android.postMessage(JSON.stringify(obj))
      }else if(window.webkit){
        let obj = {
          action:'imagePreview',
          data:{
            type:1, 
          }
        }
         window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj))
      }else{
        return
      }
      this.$store.commit("instance_before",instance_before)
      this.closeImagePreview()
    },
    

    closeImagePreview:function(){
        let instance_before = this.$store.state.instance
        instance_before.close()
    },
   
  },
  mounted() {
     window.closeImagePreview = this.closeImagePreview
  },
  created() {
     window.closeImagePreview = this.closeImagePreview
     this.postType =parseInt(sessionStorage.getItem("postType"))

    if (this.item.postType === 1){
      this.label = '活动'
      this.isBug = false
      this.isPost = false
      this.isSuggest = false
      this.isActivity = true
    }
    if (this.item.postType === 2){
      this.label = '帖子'
      this.isActivity = false
      this.isBug = false
      this.isSuggest = false
      this.isPost = true
    }
    if (this.item.postType === 3){
      this.label = 'BUG'
      this.isActivity = false
      this.isSuggest = false
      this.isPost = false
      this.isBug = true
    }
    if (this.item.postType === 4){
      this.label = '建议'
      this.isActivity = false
      this.isBug = false
      this.isPost = false
      this.isSuggest = true
    }
  }
};
</script>

<style lang="scss" scoped>
.isBug{
  background-image: url("../../assets/image/post-bug.png");
}
.isPost{
  background-image: url("../../assets/image/post-bg.png");
}
.isActivity{
  background-image: url("../../assets/image/post-activity.png");
}
.isSuggest{
  background-image: url("../../assets/image/post-suggest.png");
}
.community-postlist-item {
  position: relative;
  width: 100%;
  padding: 20px 20px;
  font-size: 12px;
  &::before {
    position: absolute;
    display: block;
    left: 50%;
    bottom: 0;
    content: "";
    width: 90%;
    height: 1px;
    background-color: #e3e3e3;
    transform: translateX(-50%);
  }
  &-header {
    display: flex;
    justify-content: start;
    &-avatar {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: inherit;
        border-radius: 50%;
      }
    }
    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      &-nickname {
        width:150px;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        font-size: 17px;
        font-weight: 500;
        color: #333333;
      }
      &-date {
        font-size: 12px;
        font-weight: 500;
        color: #999999;
      }
    }
    &-more{
      margin-top: 10px;
      img{
        width: 14px;
        height: 14px;
      }
    }
  }
  &-content {
    padding: 20px 0;
    font-size: 15px;
    font-weight: 500;
    color: #333333;
    line-height: 19px;
    .box{
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
    }
    &-label{
      display: inline-block;
      width: 40px;
      height: 17px;
      font-size: 10px;
      text-align: center;
      //background-image: url("../../assets/image/post-bg.png");
      background-size: 40px 17px;
      color: #fff;
      transform: translateY(-1px);
      margin-right: 10px;
    }
    .postContent{
      margin-top: 15px;
    }
  }
 &-imgwrap {
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: 110px;
      height: 114px;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 7px;
      margin-left: 7px;
      img {
        width: 100%;
        height: inherit;
        object-fit: cover;
      }
    }
    
  }
  &-actionbar {
    display: flex;
    align-items: center;
    margin-top: 20px;
    &-item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      color: #888888;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
      & + li {
        margin-left: 20px;
      }
      &:last-child {
        margin-left: auto;
      }
    }
    &-item:last-of-type{
      margin-right: 10px;
    }
  }
}
::v-deep .van-image--round{
    width: 100%;
    height: 100%;
}
</style>
