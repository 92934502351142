<template>
  <div class="home">
    <!-- <transition name="van-fade"> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <!-- </transition> -->
    <!-- <keep-alive>
      <component :is="isComponent"></component>
    </keep-alive> -->
    <tool-bar ref="toolBar" @onChangeFragment="onChangeFragment" v-show="$route.matched[1].name != 'Informationbase2'" v-if="Navshow"></tool-bar>
  </div>
</template>

<script>
import ToolBar from "@components/common/ToolBar";
import WInformationbase from "@components/InformationBase";
import WServiceMall from "@components/ServiceMall";
import WNetwork from "@components/Network";
import WCommunity from "@components/Community";
import WMy from "@components/My";
import { loginOut } from '../api/user';

export default {
  name: "WHome",
  data() {
    return {
      // 默认显示的组件
      isComponent: "WInformationbase",
      user:"",
      defaultHeight: '0',  //默认屏幕高度
      nowHeight:  '0',  //实时屏幕高度
      Navshow:true
    };
  },
   watch:{
     nowHeight:function(){
        if(this.defaultHeight != this.nowHeight){
         //键盘弹出操作
         this.Navshow = false
        }else{
         //键盘不弹出操作
         this.Navshow = true
        }
     }
  },
  mounted(){
    //获取页面可视高度
    this.defaultHeight =  document.documentElement.clientHeight;
    window.onresize = () => {
        return (() => {
           //实时屏幕高度
           this.nowHeight = document.documentElement.clientHeight;
        })();
    };
  },
  // 注册的组件
  components: {
    ToolBar,
    WInformationbase,
    WServiceMall,
    WNetwork,
    WCommunity,
    WMy,
  },
  computed : {
    //iPhone
    isiPhone(){
      return /iPhone|iPad/i.test(navigator.userAgent);
    },
  },
  methods: {
    // 路由的切换
    onChangeFragment: function (componentName) {
      if(componentName == "Community2"){
          this.$router.push(`/home/${componentName}`);
      }else{
         this.$router.push({
            name:componentName
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  // position: absolute;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // overflow-y: auto;
  font-size: 14px;
}
</style>
