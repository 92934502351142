<template>
  <div class="community">
    <div :class="scrolling?'topfix':''">
      <navigation-bar @search="handleSearch" >
        <template #nleft>
          <div class="leftimg">
            <img
              :src="
                $store.state.profile.headImg
                  ? $store.state.profile.headImg
                  : 'https://img01.yzcdn.cn/vant/cat.jpeg'
              "
              alt=""
            />
          </div>
        </template>
      </navigation-bar>
    </div>
<!--    <ul class="community-postlist">
      <post-list v-for="item in list" :key="item.id" :item="item" @updateArticleData="getArticleData"/>
    </ul>-->

    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <post-list v-for="item in list" :key="item.id" :item="item" @updateArticleData="getArticleData"/>
    </van-list>

    <div class="community-sendposts" @click="handleToPost"></div>
  </div>
</template>

<script>
import { findArticleList } from "../api/community";
import NavigationBar from "@components/common/NavigationBar.vue";
import PostList from "@components/community/PostList.vue";
import Mixin from "@mixin/mixin.js";
export default {
  name: "WCommunity",
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      list: [],
      isEndorse: false,
      loading : false,
      finished : false,
      pages : 0,
    };
  },
  mixins : [Mixin],
  created() {
    this.getArticleData();
  },
  components: {
    NavigationBar,
    PostList,
  },
  methods: {
    handleSearch(val) {
      console.log(val);
    },
    handleToPost() {
      this.$router.push("/addposts");
    },
    onLoad(){
      if (this.pageNum < this.pages){
        this.pageNum++
        this.getArticleData()
      }
    },
    async getArticleData() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        praiseType: 0
      };
      const result = await findArticleList(params);
      if (result.code === 0) {
        this.pages = result.data.pages
        this.loading = false
        this.finished = false

        if (result.data.list.length === 0){
          this.loading = false
          this.finished = true
        }

        if (result.data.isLastPage){
          this.finished = true
        }
        this.list = this.list.concat(result.data.list)

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.community {
  .topfix{
    position:fixed;
    width:100%;
    top:0;
    z-index:100;
    height:40px;
    background-color:#fff;
  }
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: PingFang SC;
  .leftimg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-postlist {
    // height: 3000px;
    padding-bottom: 70px;
    &-item {
      position: relative;
      width: 100%;
      padding: 20px 15px;
      &::before {
        position: absolute;
        display: block;
        left: 50%;
        bottom: 0;
        content: "";
        width: 90%;
        height: 1px;
        background-color: #e3e3e3;
        transform: translateX(-50%);
      }
      &-header {
        display: flex;
        justify-content: start;
        &-avatar {
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: inherit;
            border-radius: 50%;
          }
        }
        &-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 10px;
          &-nickname {
            font-size: 17px;
            font-weight: 500;
            color: #333333;
          }
          &-date {
            font-size: 12px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
      &-content {
        padding: 20px 0;
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        line-height: 19px;
      }
      &-imgwrap {
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: 110px;
          height: 114px;
          border-radius: 5px;
          overflow: hidden;
          img {
            width: 100%;
            height: inherit;
            object-fit: cover;
          }
        }
        &-item + li {
          margin-left: 7px;
        }
      }
      &-actionbar {
        display: flex;
        align-items: center;
        margin-top: 20px;
        &-item {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          img {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
          & + li {
            margin-left: 20px;
          }
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
  }
  &-sendposts {
    position: fixed;
    right: 40px;
    bottom: 80px;
    width: 54px;
    height: 54px;
    background-image: url("~@image/sendposts.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>
