export default {
  data(){
    return {
      scrolling : false
    }
  },
  methods : {
    //绑定监听
    listenerHander(){
      document.addEventListener("scroll",this.scrollHandle,false);
    },
    //滚动监听处理
    scrollHandle(){
      let top = document.body.scrollTop || document.documentElement.scrollTop;
      top <= 20 ? this.scrolling = false : this.scrolling = true;
    },
  },
  mounted(){
    this.listenerHander();
  }
}