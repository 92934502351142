<template>
  <div class="my">
    <div class="userinfo">
      <div class="userinfo-decs" @click="toPersonInfo">
        <div class="userinfo-decs-left">
          <div class="userinfo-decs-left-avatar" @click.stop="previewAvatar">
            <img
              :src="
                profile.headImg
                  ? profile.headImg
                  : 'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'
              "
              alt=""
            />
          </div>
          <div class="userinfo-decs-left-viptype">
            <img
              src="~@image/svip.png"
              alt=""
              v-if="profile.memberData[0].level === 3"
            />
            <img
              src="~@image/vip.png"
              alt=""
              v-else-if="profile.memberData[0].level === 2"
            />
            <img
              src="~@image/my-vip.png"
              alt=""
              v-else-if="profile.memberData[0].level === 1"
            />
          </div>
        </div>
        <div class="userinfo-decs-center">
          <div class="userinfo-decs-center-nickname">
            {{ profile.nickName ? profile.nickName : profile.mobile }}
          </div>
          <div class="userinfo-decs-center-mobile">
            {{ profile.mobile | formatPhone }}
          </div>
        </div>
        <div class="userinfo-decs-right">
          <img src="~@image/arrow-right.png" alt="" />
        </div>
      </div>
      <div class="userinfo-desc2">
        <ul class="userinfo-desc2-list">
         
          <li class="userinfo-desc2-list-item" @click="follow">
            <p class="userinfo-desc2-list-item-num">{{ profile.countFocus }}</p>
            <p class="userinfo-desc2-list-item-text">关注</p>
          </li>
          <li class="userinfo-desc2-list-item" @click="fans">
            <p class="userinfo-desc2-list-item-num">{{ profile.countFans }}</p>
            <p class="userinfo-desc2-list-item-text">粉丝</p>
          </li>
          <li class="userinfo-desc2-list-item" @click="jumpDynamic">
            <p class="userinfo-desc2-list-item-num">{{ profile.countDynamic }}</p>
            <p class="userinfo-desc2-list-item-text">动态</p>
          </li>
        </ul>
      </div>
      <!-- <div class=""></div> -->
    </div>

    <div class="userinfo2" v-if="isiPhone">
      <ul class="userinfo2-detail">
        <li class="userinfo2-detail-list" @click="order(0)">
          <img
              class="userinfo2-detail-list-img"
              src="~@image/all-order.png"
              alt=""
          />
          <p class="userinfo2-detail-list-text">全部订单</p>
          <!-- <van-badge  v-if="orderNum.allOrderQuantities !== 0 " :content="orderNum.allOrderQuantities" max="99">
            <div class="child" />
          </van-badge> -->
        </li>
        <li class="userinfo2-detail-list" @click="order(1)">
          <img
              class="userinfo2-detail-list-img"
              src="~@image/order-n.png"
              alt=""
          />
          <p class="userinfo2-detail-list-text">待付款</p>
          <van-badge  v-if="orderNum.unpaidOrderQuantity !== 0 " :content="orderNum.unpaidOrderQuantity" max="99">
            <div class="child" />
          </van-badge>
        </li>
        <li class="userinfo2-detail-list" @click="order(2)">
          <img
              class="userinfo2-detail-list-img"
              src="~@image/evaluate-n.png"
              alt=""
          />
          <p class="userinfo2-detail-list-text">待评价</p>
          <van-badge v-if="orderNum.orderQuantityToBeEvaluated !== 0 " :content="orderNum.orderQuantityToBeEvaluated" max="99">
            <div class="child" />
          </van-badge>
        </li>
        <li class="userinfo2-detail-list" @click="order(3)">
          <img
              class="userinfo2-detail-list-img"
              src="~@image/order-finish.png"
              alt=""
          />
          <p class="userinfo2-detail-list-text">已完成</p>
          <van-badge v-if="orderNum.orderQuantityCompleted !== 0 " :content="orderNum.orderQuantityCompleted" max="99">
            <div class="child" />
          </van-badge>
        </li>
      </ul>
      <div class="userinfo2-openvipbanner" @click="toRecharge">
        <img
          class="userinfo2-openvipbanner-bg"
          src="~@image/openvipbanner.png"
          alt=""
        />
      </div>
    </div>
    <ul class="moreOperations">
      <ul class="moreOperations-list">
        <li class="moreOperations-list-item">
          <div class="moreOperations-list-item-left">
            <img
              class="moreOperations-list-item-left-icon"
              src="~@image/version.png"
            />
            当前版本({{version}})
              
          </div>
          <div class="right" v-if="msg!=''">
                {{msg}}
              </div>
          <!--          <div class="moreOperations-list-item-right">
            <img src="~@image/arrow-right.png" alt="" />
          </div>-->
        </li>
        <li class="moreOperations-list-item" @click="toChangePass">
          <div class="moreOperations-list-item-left">
            <img
              class="moreOperations-list-item-left-icon"
              src="~@image/setting.png"
            />
            更换密码
          </div>
          <div class="moreOperations-list-item-right">
            <img src="~@image/arrow-right.png" alt="" />
          </div>
        </li>

        <li class="moreOperations-list-item" @click="logout">
          <div class="moreOperations-list-item-left">
            <img
              class="moreOperations-list-item-left-icon"
              src="~@image/my-out.png"
            />
            退出账号
          </div>
          <div class="moreOperations-list-item-right">
            <img src="~@image/arrow-right.png" alt="" />
          </div>
        </li>

        <li class="moreOperations-list-item" @click="toAboutUs">
          <div class="moreOperations-list-item-left">
            <img
              class="moreOperations-list-item-left-icon"
              src="~@image/profile.png"
            />
            关于我们
          </div>
          <div class="moreOperations-list-item-right">
            <img src="~@image/arrow-right.png" alt="" />
          </div>
        </li>
      </ul>
    </ul>

     <van-popup v-model="phoneBinding">
        <div class="title">游客绑定</div>
            <div class="user-box phoneNum">
              <div class="icon"></div>
              <label for="user"> +86 </label>
              <input
                id="user"
                type="text"
                name="accountnumber"
                v-model="accountnumber"
                required
                pattern="^1[3456789][0-9]{9}$"
                placeholder="请输入手机号"
              />
            </div>
        
          <div class="user-box">
              <div class="icon qrcode"></div>
              <label for="pwd">验证码</label>
              <input
                id="pwd"
                type="text"
                name="code"
                v-model="code"
                required
                placeholder="请输入验证码"
              />
              <span @click="sendSMS" v-if="issend">获取验证码</span>
              <span v-else style="color: #999999">重新获取{{ seedms }}</span>
          </div>

            <button class="btn" @click="bindingPhone">绑定</button>
     </van-popup>

     <van-popup v-model="sendVerif" class="Verif">
       <div class="over">
        <div class="content">
          <p class="send">
            我们将发送验证码短信到<br />+86&nbsp;
            <span class="phoneNumber">{{ accountnumber }}</span>
          </p>
          <!-- <p v-show="chooseIndex == 1">{{ loginError }}</p>
          <p v-if="chooseIndex == 2 && logging">{{ loginError }}</p> -->
        </div>
        <div class="btn-group">
          <span class="cancel" @click="cancel">取消</span>
          <span class="sure" @click="sure">确定</span>
        </div>
       </div>
    </van-popup>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { loginOut,checkUserVersion,getcode,updateUserMobile,getUserInfo } from "../api/user";
import { ImagePreview } from "vant";
import qs from "qs";
export default {
  name: "WMy",
  data(){
    return {
      //当前应用版本号
      version : "",
      //预览头像
      preview : [],
      account:'',
      isiPhone : !/iPhone|iPad/i.test(navigator.userAgent),
      show:'',
      orderNum:'',
      msg:"",
      phoneBinding:false,
      iosId:'',
      accountnumber:"",  //手机号
      code:"",           //验证码
      issend: true,      //是否发送验证码
      seedms: 60,        //发送验证码倒计时
      sendVerif:false,   //发送短信弹出层
      codeId: "",        // 校验验证码登录的id
    }
  },
  mounted(){
    window.closeImagePreview = this.closeImagePreview
    this.getAppVersion();
    window.getIosAppVersion = this.getIosAppVersion;
    this.getNewVersion();
    window.getOnlyId = this.getOnlyId;
    if(this.profile.mobile.length == 11){
        this.phoneBinding = false
    }else{
        this.phoneBinding = true
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  filters: {
    formatPhone: function (value) {
      return value.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
  },
  created(){
      this.isshow()
      window.closeImagePreview = this.closeImagePreview
  },
  methods: {
    //取消弹层
    cancel() {
      this.sendVerif = false;
    },

    //游客绑定手机号
    async bindingPhone(){
       
       if(this.code == ""){
         this.$toast("验证码不能为空")
         return
       }

       const data = {
         code:this.code,
         codeId:this.codeId,
         mobile:this.accountnumber,
         deviceId:this.iosId,
       }
   
       const res = await updateUserMobile(qs.stringify(data))
       if(res.code === 0 ){
         this.$toast("绑定成功！")
         this.phoneBinding = false
       }else{
         this.$toast("网络错误，请联系管理员！")
         this.phoneBinding = false
       }
       
       
    },

    //去订单页
    order(tab){
      this.$router.push({
        name : "order",
        query:{
          tabId:tab
        }
      });
    },

    //获取ios唯一id
    getOnlyId(id){
        this.iosId = id
    },

    // 获取验证码
    sendSMS() {
      if (!/^1[3|4|5|7|8|9]\d{9}$/.test(this.accountnumber)) {
        this.$toast("请填写手机号");
      } else {
        this.sendVerif = true;
      }
    },
    
     //确认发送验证码
    async sure() {
      const data = {
        mobile: this.accountnumber,
        type: 1,
      };
      const result = await getcode(qs.stringify(data));
      if (result.code === 0) {
        this.getTime();
        this.codeId = result.data.codeId;
      } else {
        this.$toast(result.msg);
      }
      this.sendVerif = false;
    },
    
     // 倒计时
    getTime() {
      let num = setInterval(() => {
        this.issend = false;
        this.seedms -= 1;
        if (this.seedms < 1) {
          clearInterval(num);
          if (this.seedms < 1) {
            this.issend = true;
            this.seedms = 60;
          }
        }
      }, 1000);
    },

    //显示
    async isshow(){
       const res = await getUserInfo()
       if(res.code === 0){
        this.account = JSON.parse(res.data.mobile)
        this.preview.push(res.data.headImg)
        if(typeof this.account == Number){
            this.show = false
        }else{
            this.show = true
        }
        this.orderNum = res.data
       }
     
    },
    //跳转动态
    jumpDynamic(){
       this.$router.push({
         name:'dynamic'
       })
    },
    //查看头像
    previewAvatar(){
      //如果是游客就不让打开图片
      if(this.show == true){
          const instance_before = ImagePreview({
            images: this.preview,
            showIndex: true,
            asyncClose: true,
            closeable: true,
            onClose(){
              if(window.android){
                    let obj = {
                    action:'imagePreview',
                    data:{
                      type:0, 
                    }
                  }
                 window.android.postMessage(JSON.stringify(obj))
              }else if(window.webkit){
                    let obj = {
                    action:'imagePreview',
                    data:{
                      type:0, 
                    }
                  }
                 window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj))
              }else{
                return
              }
              
            }
          });
      
       this.$nextTick(()=>{
          let close = document.getElementsByClassName("van-image-preview__close-icon")[0]
          close.addEventListener("click",function(){
              instance_before.close()
          })
       })
      if(window.android){
        let obj = {
          action:'imagePreview',
          data:{
            type:1, 
          }
        }
        window.android.postMessage(JSON.stringify(obj))
      }else if(window.webkit){
        let obj = {
          action:'imagePreview',
          data:{
            type:1, 
          }
        }
         window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj))
      }else{
        return
      }
      this.$store.commit("instance_before",instance_before)
      this.closeImagePreview()
      }else{
        return
      }
    },
     closeImagePreview:function(){
        let instance_before = this.$store.state.instance
        instance_before.close()
    },
    getIosAppVersion(){
      alert(window.getIosAppVersion);
    },
    //获取应用版本号
    getAppVersion(){
      if(window.android){
        this.version = window.android.getVersionName();
      }else if(window.webkit){
        this.version = window.IOSInfo;
      }
    },
    //获取是否是最新版本
    async getNewVersion(){
       
       if(window.android){
         const data = {
           type:3,
           version:this.version
         }
         console.log(data)
         console.log(this.version)
         const res = await checkUserVersion(JSON.stringify(data))
         if(res.code === 0){
           this.msg = res.msg
         }
       }else if(window.webkit){
          const data = {
           type:1,
           version:this.version
         }
         const res = await checkUserVersion(JSON.stringify(data))
         if(res.code === 0){
           this.msg = res.msg
         }
       }
    },
    // 关注
    follow(){ 
      if(window.android){
        let obj = {
         action:"attentionList",
         data:{
           type:0
         }
       }
       window.android.postMessage(JSON.stringify(obj))
      }else if(window.webkit){
        let obj = {
         action:"attentionList",
         data:{
           type:0
         }
       }
      window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(obj)
        );
      }
    },
    //粉丝
    fans(){
       if(window.android){
        let obj = {
         action:"attentionList",
         data:{
           type:1
         }
       }
       window.android.postMessage(JSON.stringify(obj))
      }else if(window.webkit){
       let obj = {
         action:"attentionList",
         data:{
           type:1
         }
       }
      window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(obj)
        );
      }
    },

    async logout() {
      //退出登录
       this.$dialog.confirm({
        message:'是否要退出登录？',
        confirmButtonColor:'#2b6eff'
        }).then(()=>{
           const result =  loginOut()
           window.localStorage.setItem("userInfo", "");
            if (window.android) {
             // 安卓
            window.android.postMessage(
              JSON.stringify({
              action: "logout",
              })
             );
           } else if (window.webkit) {
              // ios 环境下
              window.webkit.messageHandlers.sendMessageForVue.postMessage(
                JSON.stringify({
                  action: "logout",
                })
                );
           }
          this.$router.replace("/login");              

        }).catch(()=>{
           console.log('点击了取消')
        })
    },
    toRecharge() {
      //去充值
      this.$router.push("/ServiceMallDef");
    },
    //去更换密码
    toChangePass() {
      this.$router.push("/changepass");
    },
    toPersonInfo() {
      //去个人信息
      this.$router.push("/personal");
    },
    toAboutUs() {
      //去关于我们
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
.my {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: PingFang SC;
  /*头像*/
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img{
      width:100%;
      height:220px;
    }
  }
  /**/
  .userinfo {
    // display: flex;
    // align-items: center;
    // height: 108px;
    width: 100%;
    padding: 0 15px;
    margin-top: 10px;
    &-decs {
      display: flex;
      align-items: center;
      &-left {
        position: relative;
        width: 75px;
        height: 75px;
        &-avatar {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        &-viptype {
          position: absolute;
          right: 0;
          bottom: 5%;
          width: 18px;
          height: 17px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-center {
        flex: 1;
        margin-left: 15px;
        &-nickname {
          width:200px;
          white-space:nowrap;
          text-overflow:ellipsis;
          overflow:hidden;
          font-size: 17px;
          font-weight: bold;
          color: #333333;
          & + div {
            margin-top: 10px;
          }
        }
        &-mobile {
          font-size: 14px;
          font-weight: 500;
          color: #999999;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        width: 11px;
        height: 100;
        img {
          width: 6px;
          height: 11px;
        }
      }
    }
    &-desc2 {
      width: 100%;
      margin: 15px 0;
      &-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        &-item {
          flex: 1;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-around;
          &-num {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
          }
          &-text {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
  .userinfo2 {
    position: relative;
    padding: 15px 15px 0 15px;
    // padding: 15px 0;
    // height: 100px;
    // background-color: #414f45;
    &-openvipbanner {
      width: 100%;
      height: 50px;
      &-bg {
        width: 100%;
        height: inherit;
        // background-image: url("~@image/openvipbanner.png");
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
      }
    }
    &-detail {
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &-list {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        &-img {
          width: 22px;
          height: 22px;
        }
        &-text {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 500;
          color: #333333;
        }
        ::v-deep .van-badge__wrapper{
           position: absolute;
           top: 0;
           right: 0;
        }
      }
    }
    &::before {
      position: absolute;
      display: block;
      top: -10px;
      left: 0;
      content: " ";
      width: 375px;
      height: 10px;
      background: #f4f4f4;
    }
  }
  .moreOperations {
    position: relative;
    padding: 0 15px;
    padding-top: 5px;
    &-list {
      width: 100%;
      &-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        &-left {
          display: flex;
          align-items: center;
          flex: 1;
          &-icon {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
        &-right {
          display: flex;
          align-items: center;
          height: 100%;
          width: 7px;
          img {
            width: 100%;
            height: 12px;
          }
        }
        .right{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    /*&::before {
      position: absolute;
      display: block;
      top: -10px;
      left: 0;
      content: " ";
      width: 375px;
      height: 10px;
      background: #f4f4f4;
    }*/
  } 
}
::v-deep .van-popup{
  width: 345px;
  height: 297px;
  border-radius: 10px;
  text-align: center;
  .title{
    font-size: 19px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-top: 30px;
  }

  .phoneNum{
    margin-top: 20px !important;
  }

  .user-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width:calc(100% -40px);
            height: 54px;
            padding: 20px 0px;
            margin:0 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            // opacity: 0.2;
            .icon {
              flex-shrink: 0;
              width: 10px;
              height: 15px;
              margin-right: 8px;
              background-image: url("~@image/userphone1.png");
              background-size: 100% 100%;
            }
            label {
              flex-shrink: 0;
              width: 50px;
            }
            input {
              flex: 1;
              &:valid {
                color: #4587ff;
              }
            }
            #user{
              padding-left: 23px;
            }
            #pwd{
              width:80px;
              flex:1 0 80px;
              padding-left: 20px;
            }
            .icon.suo {
              width:15px;
              height:15px;
              flex-shrink: 0;
              background-image: url("~@image/suo1.png");
              background-size:100%;
            }
            .icon.qrcode {
              width:11px;
              height:14px;
              margin-left:2px;
              flex-shrink: 0;
              background-image: url("~@image/qrcode1.png");
              background-size:100%;
            }
            span {
              color: #4588ff;
              flex-shrink: 0;
            }
          }
   
   .btn{
     margin-top: 30px;
     width: 295px;
     height: 49px;
     background: #4588FF;
     box-shadow: 0px 8px 8px 0px rgba(69, 136, 255, 0.33);
     border-radius: 49px;
     font-size: 17px;
     font-family: PingFang SC;
     font-weight: 500;
     color: #FEFEFE;
   }
   
   .over{
     width: 100%;
     height: 100%;
   }
   .content {
      height: 115px;
      padding: 50px 60px 0;
    p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0 45px;

    span {
      font-size: 15px;
      font-weight: 500;
      color: #999;
    }

    .sure {
      color: #4588ff;
    }
  }
}
.Verif{
   width: 285px;
   height: 169px;
}
</style>

<style>
  ::v-deep .van-dialog{
    font-size: 20px !important;
  }
</style>
